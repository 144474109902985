@font-face {
  font-family: 'Geogrotesque-Regular';
  src: url('fonts/Geogrotesque-Regular.eot');
  src: url('fonts/Geogrotesque-Regular.eot?#iefix') format('embedded-opentype'),
       url('fonts/Geogrotesque-Regular.svg#Geogrotesque-Regular') format('svg'),
       url('fonts/Geogrotesque-Regular.ttf') format('truetype'),
       url('fonts/Geogrotesque-Regular.woff') format('woff'),
       url('fonts/Geogrotesque-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geogrotesque-SemiBold';
  src: url('fonts/Geogrotesque-SemiBold.eot');
  src: url('fonts/Geogrotesque-SemiBold.eot?#iefix') format('embedded-opentype'),
       url('fonts/Geogrotesque-SemiBold.svg#Geogrotesque-SemiBold') format('svg'),
       url('fonts/Geogrotesque-SemiBold.ttf') format('truetype'),
       url('fonts/Geogrotesque-SemiBold.woff') format('woff'),
       url('fonts/Geogrotesque-SemiBold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geogrotesque-Medium';
  src: url('fonts/Geogrotesque-Medium.eot');
  src: url('fonts/Geogrotesque-Medium.eot?#iefix') format('embedded-opentype'),
       url('fonts/Geogrotesque-Medium.svg#Geogrotesque-Medium') format('svg'),
       url('fonts/Geogrotesque-Medium.ttf') format('truetype'),
       url('fonts/Geogrotesque-Medium.woff') format('woff'),
       url('fonts/Geogrotesque-Medium.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
